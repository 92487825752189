
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("军粮新增", ["军粮管理;add_extra_point_records"])
    })

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const add_extra_point_record = ref({
      point: 0,
      note: ""
    })

    const onCancel = () => {
      router.push({ name: "add_extra_point_records" })
    }

    const isSubmit = ref(false)

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (isSubmit.value) {
        return
      }

      if (!formEl) return

      formEl.validate((valid) => {
        if (valid) {
          isSubmit.value = true

          let params = {
            add_extra_point_record: {
              ...add_extra_point_record.value
            }
          }

          store
            .dispatch(
              Actions.CREATE_ADD_EXTRA_POINT_RECORD,
              params
            )
            .then(() => {
              router.push({ name: "add_extra_point_records" })
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "确认",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              })

              isSubmit.value = false
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        } else {
          isSubmit.value = false
          return false
        }
      })
    }

    return {
      onSubmit,
      add_extra_point_record,
      rules,
      onCancel,
      formRef,
    }
  },
})
